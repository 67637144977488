import { render, staticRenderFns } from "./index.vue?vue&type=template&id=27823aad&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=27823aad&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27823aad",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonSectionTitle: require('/home/circleci/repo/frontend/components/common/section-title.vue').default,TopSnsInstagramTimeline: require('/home/circleci/repo/frontend/components/top/sns/instagram-timeline.vue').default,CommonWrapSection: require('/home/circleci/repo/frontend/components/common/wrap-section.vue').default})
