import { render, staticRenderFns } from "./contact.vue?vue&type=template&id=054bdbd4&scoped=true&"
var script = {}
import style0 from "./contact.vue?vue&type=style&index=0&id=054bdbd4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "054bdbd4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonSectionTitle: require('/home/circleci/repo/frontend/components/common/section-title.vue').default,CommonWrapSection: require('/home/circleci/repo/frontend/components/common/wrap-section.vue').default})
