import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2fcbb4a3&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=2fcbb4a3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fcbb4a3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopKvStaticImg: require('/home/circleci/repo/frontend/components/top/kv/static-img.vue').default,TopInformation: require('/home/circleci/repo/frontend/components/top/information/index.vue').default,TopPickUp: require('/home/circleci/repo/frontend/components/top/pick-up/index.vue').default,TopAbout: require('/home/circleci/repo/frontend/components/top/about.vue').default,TopContact: require('/home/circleci/repo/frontend/components/top/contact.vue').default,TopSns: require('/home/circleci/repo/frontend/components/top/sns/index.vue').default,TopPartner: require('/home/circleci/repo/frontend/components/top/partner/index.vue').default})
