import { defineComponent } from 'vue';
var hashs = [{ name: 'INFORMATION' }, { name: '開催情報' }];
export default defineComponent({
    name: 'CTopInformation',
    props: {
        data: {
            type: Object,
            default: function () { return ({}); }
        }
    },
    setup: function () {
        // APIService.getNews().then((responsive) => {
        //   if (responsive) {
        //     const data = responsive.data as NewsResponse
        //     inforNews.value = data.news || null
        //   }
        // })
        return { hashs: hashs };
    }
});
